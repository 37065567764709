import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { _fetch } from '../config/Api'
import { base_url } from '../config/config'
import { ColorRing } from 'react-loader-spinner';
const Closeposition = () => {
  const [symbol, setSymbol] = useState('');
  const [loading, setLoading] = useState(false);



  const handledashboard = async (e) => {
      e.preventDefault();
      if (!symbol){
        toast.dismiss()
        toast.error("Please enter the details");
        return;
      }


      setLoading(true);  // Show loading spinner
      try {
        const requestData = {
          batchId:symbol,
       

  
        };
  
        let response = await _fetch(`${base_url}api/trader/closeBatchTrade`, 'post', requestData);
  
        if (response.success === true) {
          setSymbol('')
          toast.dismiss()
          toast.success(response.message);
          // setData(response);
        } else {
          toast.dismiss()
          toast.error(response.message);
        }
      } catch (error) {
        console.log("Error details", error);
      }finally{
        setLoading(false);  // Hide loading spinner when done
      }
    }

  return (
    <>
  <div className="conatiner-fluid">
                <div className="d-flex justify-content-center align-items-center h-100">
                    <div className="sign-page ">
                    <div className="heading text-center mb-3">
                            <h3>Closed Positions</h3>
                        </div>
                        <form >
                            <div className="mb-3">
                                <label htmlFor="exampleInputEmail1" className="form-label">Batch Id </label>
                                <input type="text" id="email"   value={symbol}   onChange={(e) => setSymbol(e.target.value)} autoComplete='off' className="form-control" aria-describedby="emailHelp" />
                            </div>

                           
                            
                            <button type="submit" className="btn btn-primary m"  onClick={handledashboard}>
                               
                                {loading ? (
                                    <ColorRing
                                        height={20}
                                        width={20}
                                        color="#fff"
                                        ariaLabel="loading"
                                    />
                                ) : (
                                    'Submit'
                                )}
                                </button>
                            <div>

                            </div>
                        </form>
                    </div>
                </div>
            </div>
    </>
  )
}

export default Closeposition
