import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { _fetch } from '../config/Api'
import { base_url } from '../config/config'
import { ColorRing } from 'react-loader-spinner';
import dateFormat from "dateformat";
import { Pagination } from "antd";
const Positionhistory = () => {
  const [data, setData] = useState()
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSer, setpageSer] = useState(0);
  const handledashboard = async (page = 1, pageSize = 10) => {
      try {
        const skip = (page - 1) * pageSize;
        setpageSer(skip);
        const requestData = {
          limit: pageSize,
          skip: skip,
          type:"FUTURE",
        };
  

      let response = await _fetch(`${base_url}api/trader/orders`, 'post', requestData);

          if (response.success === true) {
              setData(response);
          } else {
              toast.error(response.message);
          }
      } catch (error) {
          console.log("Error details", error);
      }
  }



  useEffect(() => {
    handledashboard(currentPage, 10);
  }, [currentPage]);
  const handlePaginationChange = (pageOne) => {
    setCurrentPage(pageOne);
   
  };

  const records = () => {
    if (data && data?.data && data?.data?.length > 0) {
        return data?.data?.map((row, i) => {
          const serialNumber = pageSer + i + 1;
            return (
                <tr className="table-row" key={i}>
                    <td className="tabel-text w inter">{serialNumber}</td>
                    <td className="tabel-text w inter">{row?.userId?.userId}</td>
                    <td className="tabel-text w inter">{row?.userId?.name}</td>

                    <td className="tabel-text w inter">{row?.orderId}</td>
                    <td className="tabel-text w inter">{row?.symbol}</td>
                    <td className="tabel-text w inter">{row?.status}</td>
                    <td className="tabel-text w inter">{row?.price}</td>
                    <td className="tabel-text w inter">{row?.origQty}</td>
                    <td className="tabel-text w inter">{row?.type}</td>
                    <td className="tabel-text w inter">{row?.side}</td>
                    <td className="tabel-text w inter">{row?.quantity}</td>
                    <td className="tabel-text w inter">{row?.avgPrice}</td>
                    <td className="tabel-text w inter">{row?.executedQty}</td>
                    <td className="tabel-text w inter">{row?.cumQty}</td>
                    <td className="tabel-text w inter">{row?.cumQuote}</td>
                    <td className="tabel-text w inter">{row?.timeInForce}</td>
                    <td className="tabel-text w inter">{row?.side}</td>
                    <td className="tabel-text w inter">{row?.positionSide}</td>
                    <td className="tabel-text w inter">{row?.stopPrice}</td>
                    <td className="tabel-text w inter">{row?.workingType}</td>
                    <td className="tabel-text w inter">{row?.origType}</td>
                    <td className="tabel-text w inter">{row?.priceMatch}</td>
                    <td className="tabel-text w inter">{row?.selfTradePreventionMode}</td>
                    <td className="tabel-text w inter">{row?.goodTillDate}</td>
                    <td className="tabel-text w inter">{row?.batchId}</td>
                    <td className="tabel-text w inter">{row?.fee}</td>
                    <td className="tabel-text w inter">{row?.exchangeName}</td>
                    <td>{dateFormat(row.createdAt, "yyyy-mm-dd, hh:mm:ss TT")}</td>

                   
                </tr>
            );
        });
    } else {
        return (
            <tr>
                <td colSpan="13">Data Not Found</td>
            </tr>
        );
    }
};
  return (
    <>
<div className="section2  ">
               
                    <div className="table-sec">
                        <div className="transactions">
                            
                            <table className="table sec">
                        <thead>
                            <tr>
                            <th scope="col">S.No.</th>
                            <th scope="col">UID</th>
                            <th scope="col">Name</th>
                                <th scope="col">Order Id</th>
                                <th scope="col">Symbol</th>
                                <th scope="col">Status</th>
                                <th scope="col">Amount</th>
                                <th scope="col">Price</th>
                                <th scope="col">Type</th>
                                <th scope="col">Mode</th>
                                <th scope="col">Quantity  </th>
                                <th scope="col">Avg Price</th>
                                <th scope="col">Executed Qty</th>
                                <th scope="col">Cum Qty</th>
                                <th scope="col">Cum Quote</th>
                                <th scope="col">time InForce</th>
                                <th scope="col">Side</th>
                                <th scope="col">Position Side</th>
                                <th scope="col">Stop Price</th>
                                <th scope="col">Working Type</th>
                                <th scope="col">OrigType</th>
                                <th scope="col">Price Match</th>
                                <th scope="col">selfTradePreventionMode</th>
                                <th scope="col">goodTillDate</th>
                                <th scope="col">BatchId</th>
                                <th scope="col">fee</th>
                                <th scope="col">Exchange Name</th>
                                <th scope="col">Date</th>
                                {/* <th scope="col">Closetime</th> */}
                            </tr>
                        </thead>
                        <tbody>
                        {records()}
                        </tbody>
                        </table>
                        </div>
                        <div className="pagination">
              <Pagination
                style={{ cursor: "pointer" }}
                className="mx-auto"
                current={currentPage || 1}
                total={data?.count || 0}
                pageSize={10}
                onChange={handlePaginationChange}
                showSizeChanger={false}
                showQuickJumper={false}
              />

            </div>
                
                </div>
            </div>
    </>
  )
}

export default Positionhistory
