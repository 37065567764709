import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { Pagination } from "antd";

import { ColorRing } from 'react-loader-spinner';
import { base_url } from '../config/config';
import _fetch from '../config/Api';
import dateFormat from "dateformat";

const Tradespotopen = () => {
  const [data, setData] = useState()
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSer, setpageSer] = useState(0);

  const handledashboard = async (page = 1, pageSize = 10) => {
      try {
          const skip = (page - 1) * pageSize;
          setpageSer(skip);
          const requestData = {
              limit: pageSize,
              skip: skip,
              type:"SPOT",


          };

          let response = await _fetch(`${base_url}api/trader/orders`, 'post', requestData);

          if (response.success === true) {
              setData(response);
          } else {
              toast.error(response.message);
          }
      } catch (error) {
          console.log("Error details", error);
      }
  }


  useEffect(() => {
      handledashboard(currentPage, 10);
  }, [currentPage]);
  const handlePaginationChange = (pageOne) => {
      setCurrentPage(pageOne);

  };

  const records = () => {
      if (data && data?.data && data?.data?.length > 0) {
          return data?.data?.map((row, i) => {
              const serialNumber = pageSer + i + 1;
              return (
                  <tr className="table-row" key={i}>
                      <td className="tabel-text w inter">{serialNumber}</td>
                      <td className="tabel-text w inter">{row?.orderId}</td>
                      <td className="tabel-text w inter">{row?.symbol}</td>
                      <td className="tabel-text w inter">{row?.status}</td>
                      <td className="tabel-text w inter">{row?.price}</td>
                      <td className="tabel-text w inter">{row?.origQty}</td>
                      <td className="tabel-text w inter">{row?.type}</td>
                      <td className="tabel-text w inter">{row?.side}</td>
                      <td className="tabel-text w inter">{row?.quantity}</td>
                      <td className="tabel-text w inter">{row?.orderType}</td>
                      <td className="tabel-text w inter">{row?.exchangeName}</td>
                      <td className="tabel-text w inter">{row?.batchId}</td>
                      
                      
                      <td>{dateFormat(row.createdAt, "yyyy-mm-dd, hh:mm:ss TT")}</td>


                  </tr>
              );
          });
      } else {
          return (
              <tr>
                  <td colSpan="13">Data Not Found</td>
              </tr>
          );
      }
  };
  return (
   <>
 <div className="section2">

<div className="table-sec">
    <div className="transactions">

        <table className="table sec">
            <thead>
                <tr>
                    <th scope="col">S.No.</th>
                    <th scope="col">Order Id</th>
                    <th scope="col">Symbol</th>
                    <th scope="col">Status</th>
                    <th scope="col">Amount</th>
                    <th scope="col">Price</th>
                    <th scope="col">Type</th>
                    <th scope="col">Mode</th>
                    <th scope="col">Quantity  </th>
                    <th scope="col">OrderType</th>
                    <th scope="col"> ExchangeName</th>
                    <th scope="col">BatchId</th>
                    <th scope="col">Date</th>
                    {/* <th scope="col">Closetime</th> */}
                </tr>
            </thead>
            <tbody>
                {records()}
            </tbody>
        </table>
    </div>
    <div className="pagination">
        <Pagination
            style={{ cursor: "pointer" }}
            className="mx-auto"
            current={currentPage || 1}
            total={data?.count || 0}
            pageSize={10}
            onChange={handlePaginationChange}
            showSizeChanger={false}
            showQuickJumper={false}
        />

    </div>

</div>
</div>
   </>
  )
}

export default Tradespotopen
