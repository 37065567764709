import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { _fetch } from '../config/Api'
import { base_url } from '../config/config'
import { ColorRing } from 'react-loader-spinner';
const NewTrade = () => {
    const [symbol, setSymbol] = useState('');
    const [mode, setMode] = useState('');
    const [timeex, setTimeex] = useState('GTC');
    const [type, setType] = useState('');
    const [quantity, setQuantity] = useState(0);
    const [leverage, setLeverage] = useState(0);
    const [loading, setLoading] = useState(false);

    const handledashboard = async (e) => {
        e.preventDefault();


        if (!symbol || !mode ||!timeex || !type || !quantity || !leverage){
            toast.dismiss()
            toast.error("Please enter the details");
            return;
          }
          setLoading(true);
        try {
            const requestData = {
                symbol: symbol,
                mode: mode,
                timeInForce: timeex,
                type: type,
                quantity: parseInt(quantity),
                leverage: parseInt(leverage),


            };

            let response = await _fetch(`${base_url}api/trader/trade`, 'post', requestData);

            if (response.success === true) {
                setSymbol('')
                setMode('')
                setTimeex('GTC')
                setType('')
                setQuantity(0)
                setLeverage(0)
                toast.dismiss()
                toast.success(response.message);
                // setData(response);
            } else {
                toast.error(response.message);
            }
        } catch (error) {
            console.log("Error details", error);
        }finally {
            setLoading(false);
          }
    }


    return (
        <>
            <div className="row mt-5 d-flex align-items-center justify-content-center">
                <div className='col-6'>
                    <form className='' >
                        <div className='form-group'>
                            <label className=''>Symbol</label>
                            <input type='text' className='form-control' value={symbol} onChange={(e) => setSymbol(e.target.value)} />
                            {/* <label className=''>Mode</label>
                            <select

                                className="form-control input form-select"
                                aria-label="Default select example"
                                value={symbol}
                                onChange={(e) => setSymbol(e.target.value)}
                            >
                                <option >Symbol</option>
                                <option value={'SOLUSDT'}>SOLUSDT</option>
                            </select> */}
                        </div>
                        <div className='form-group'>

                            <label className=''>Mode</label>
                            <select

                                className="form-control input form-select"
                                aria-label="Default select example"
                                value={mode}
                                onChange={(e) => setMode(e.target.value)}
                            >
                                <option >mode</option>
                                <option value={'BUY'}>Buy</option>
                                <option value={'SELL'}> Sell</option>
                            </select>
                        </div>
                        <div className='form-group'>
                            <label className=''>TimeInForce</label>

                            <input type='text' className='form-control' value={timeex} onChange={(e) => setTimeex(e.target.value)} readOnly />
                        </div>
                        <div className='form-group'>
                            <label className=''>Type</label>


                            <select

                                className="form-control input form-select"
                                aria-label="Default select example"
                                value={type}
                                onChange={(e) => setType(e.target.value)}
                            >
                                <option >Type</option>
                                <option value={'MARKET'}>Market</option>
                                {/* <option value={'LIMIT'}> limit</option> */}
                            </select>
                        </div>

                        {/* <div className='form-group'>
                            <label className=''>quantity</label>

                            <input type='number' className='form-control' onChange={(e) => setQuantity(e.target.value)} />
                            <label className=''>leverage</label>

                            <input type='number' className='form-control' onChange={(e) => setLeverage(e.target.value)} />
                        </div> */}
                        <div className='form-group'>
                            <label className=''>quantity</label>
                            <input type='number' className='form-control' min='0' value={quantity}    onChange={(e) => setQuantity(e.target.value)} />
                            <label className=''>leverage</label>
                            <input type='number' className='form-control' min='0'   value={leverage} onChange={(e) => setLeverage(e.target.value)} />
                        </div>
                        <div className='row mt-3 text-center'>
                            <div className='col-12'>
                            {loading ? (
                <ColorRing
                  height={65}
                  width={65}
                  colors={['#08d1d1', '#08d1d1', '#08d1d1', '#08d1d1', '#08d1d1']}
                  ariaLabel="loading"
                />
              ) : (
                                <button className='p-2 bg-success text-white' onClick={handledashboard} >Place Order</button>
                            )}
                            </div>


                        </div>
                    </form>
                </div>

            </div>
        </>
    )
}

export default NewTrade