import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { _fetch } from '../config/Api'
import { base_url } from '../config/config'
import { ColorRing } from 'react-loader-spinner';
import dateFormat from "dateformat";
import { Pagination } from "antd";
const Tradermember = () => {
    const [data, setData] = useState()
    const [spotBalances, setSpotBalances] = useState({});
    const [futureBalances, setFutureBalances] = useState({});
    const [loading, setLoading] = useState({}); 
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSer, setpageSer] = useState(0);
    const handledashboard = async (page = 1, pageSize = 10) => {
        try {

            const skip = (page - 1) * pageSize;
            setpageSer(skip);
            const requestData = {
              limit: pageSize,
              skip: skip
            };
            let response = await _fetch(`${base_url}api/trader/members`, 'post', requestData);

            if (response.success === true) {
                setData(response);
            } else {
                toast.dismiss()
                toast.error(response.message);
                
            }
        } catch (error) {
            console.log("Error details", error);
        }
    }

    const getbalance = async (_id, type) => {
       
        setLoading((prevLoading) => ({ 
            ...prevLoading, 
            [_id]: { ...prevLoading[_id], [type]: true } 
        }));

        try {
            const requestData = {
                userId: _id,
                type: type 
            };

            let response = await _fetch(`${base_url}api/trader/walletBalance`, 'post', requestData);
            console.log('response', response);

            if (response.success === true) {
                if (type === 'spot') {
                    setSpotBalances((prevBalances) => ({ ...prevBalances, [_id]: response.data }));
                } else if (type === 'future') {
                    setFutureBalances((prevBalances) => ({ ...prevBalances, [_id]: response.data }));
                }
                toast.dismiss()
                toast.success(`${type} balance fetched successfully`);
            } else {
                toast.error(response.message);
            }
        } catch (error) {
            console.error('Error details:', error);
        } finally {
           
            setLoading((prevLoading) => ({ 
                ...prevLoading, 
                [_id]: { ...prevLoading[_id], [type]: false } 
            }));
        }
    };

    useEffect(() => {
        handledashboard(currentPage, 10);
      }, [currentPage]);
      const handlePaginationChange = (pageOne) => {
        setCurrentPage(pageOne);
       
      };

    const records = () => {
        if (data && data?.data && data?.data?.length > 0) {
            return data?.data?.map((row, i) => {
                return (
                    <tr className="table-row" key={i}>
                        <td className="tabel-text w inter">{i + 1}</td>
                        <td className="tabel-text w inter">{row?.userId}</td>
                        <td className="tabel-text w inter">{row?.name}</td>
                        <td className="tabel-text w inter">{row?.email}</td>
                        {/* <td className="tabel-text w inter">{row?.createdAt}</td> */}
                        <td className="tabel-text w inter">{dateFormat(row.createdAt, "yyyy-mm-dd, hh:mm:ss TT")}</td>

                        {/* Spot Balance Button */}
                        <td className="tabel-text w inter">
                            {loading[row._id]?.spot ? (
                                <ColorRing
                                    height={35}
                                    width={35}
                                    colors={['#08d1d1', '#08d1d1', '#08d1d1', '#08d1d1', '#08d1d1']}
                                    ariaLabel="loading"
                                />
                            ) : (
                                <button
                                    className='btn btn-primary'
                                    onClick={() => getbalance(row._id, 'spot')}
                                >
                                    {spotBalances[row._id]?.free
                                        ? `${parseFloat(spotBalances[row._id].free).toFixed(4)} USDT`
                                        : 'USDT'}
                                </button>
                            )}
                        </td>

                        {/* Future Balance Button */}
                        <td className="tabel-text w inter">
                            {loading[row._id]?.future ? (
                                <ColorRing
                                    height={35}
                                    width={35}
                                    colors={['#08d1d1', '#08d1d1', '#08d1d1', '#08d1d1', '#08d1d1']}
                                    ariaLabel="loading"
                                />
                            ) : (
                                <button
                                    className='btn btn-primary'
                                    onClick={() => getbalance(row._id, 'future')}
                                >
                                    {futureBalances[row._id]?.walletBalance
                                        ? `${parseFloat(futureBalances[row._id].walletBalance).toFixed(4)} USDT`
                                        : 'USDT'}
                                </button>
                            )}
                        </td>
                    </tr>
                );
            });
        } else {
            return (
                <tr>
                    <td colSpan="13">Data Not Found</td>
                </tr>
            );
        }
    };

    return (
        <>
            <div className="section2">
                <div className="container">
                    <div className="table-sec">
                        <div className="transactions">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col">S.No</th>
                                        <th scope="col">UserId</th>
                                        <th scope="col">Name</th>
                                        <th scope="col">Email</th>
                                        <th scope="col">Date</th>
                                        <th scope="col">Spot Balance</th>
                                        <th scope="col">Future Balance</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {records()}
                                </tbody>
                            </table>
                        </div>
                        <div className="pagination">
              <Pagination
                style={{ cursor: "pointer" }}
                className="mx-auto"
                current={currentPage || 1}
                total={data?.count || 0}
                pageSize={10}
                onChange={handlePaginationChange}
                showSizeChanger={false}
                showQuickJumper={false}
              />

            </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Tradermember;

