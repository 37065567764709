import React from 'react'

const Spottrade = () => {
  return (
  <>
  <h1>hello spot NEW trade</h1>
  </>
  )
}

export default Spottrade
