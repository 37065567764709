import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import '../Assets/Css/Style.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { useNavigate } from 'react-router';
import LogoutModal from '../Auth/LogoutModal';

const Sidebar = () => {
    const navigate = useNavigate();
    const [isExpanded, setIsExpanded] = useState(false);
    const [openSection, setOpenSection] = useState(null);
    const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);

    // Toggle sidebar
    const handleToggle = () => {
        setIsExpanded(!isExpanded);
    };

    const logout = () => {
        localStorage.clear('token');
        navigate('/');
    };

    // Toggle dropdowns
    const handleSectionToggle = (section) => {
        setOpenSection(openSection === section ? null : section);
    };

    return (
        <>

            <div className={`sidebar__wrap ${isExpanded ? 'expand' : ''}`}>
                <div className="wrapper">
                    <aside id="sidebar" className={isExpanded ? 'expand' : ''}>
                        <div className="d-flex">
                            <button className="toggle-btn" type="button" onClick={handleToggle}>
                                <i class="bi bi-toggles"></i>
                            </button>
                            <div className="sidebar-logo">
                                <a href="#">Future Trader</a>
                            </div>
                        </div>
                        <ul className="sidebar-nav">
                            <li className="sidebar-item">
                                <NavLink to='/dashboard' className="sidebar-link">
                                    <i className="bi bi-speedometer2" />
                                    <span>Dashboard</span>
                                </NavLink>
                            </li>
                            <li className="sidebar-item">
                                <NavLink to='/Tradermember' className="sidebar-link">
                                    <i className="bi bi-person" />
                                    <span>Member</span>
                                </NavLink>
                            </li>
                            <li className="sidebar-item">
                                <NavLink to='/activity' className="sidebar-link">
                                <i class="bi bi-alexa"></i>
                                    <span>Activity</span>
                                </NavLink>
                            </li>
                            <li className="sidebar-item">
                                <a href="#" className="sidebar-link collapsed has-dropdown" onClick={() => handleSectionToggle('auth')}>
                                    <i className="bi bi-person" />
                                    <span>Future Trade</span>
                                </a>
                                <ul id="auth" className={`sidebar-dropdown list-unstyled ${openSection === 'auth' ? 'show' : 'collapse'}`}>
                                    <li className="sidebar-item outcome">
                                        <NavLink to='/new_trade' className=' navlink sidebar-link collapsed'>
                                            New Trade
                                        </NavLink>
                                    </li>
                                    <li className="sidebar-item outcome">
                                        <NavLink to='/closeposition' className=' navlink sidebar-link collapsed'>
                                        Closed Positions
                                        </NavLink>
                                    </li>
                                    <li className="sidebar-item outcome">
                                        <NavLink to='/tradeopen' className=' navlink sidebar-link collapsed'>
                                        trades
                                        </NavLink>
                                    </li>
                                    {/* <li className="sidebar-item outcome">
                                <NavLink to='/closeposition' className=' navlink sidebar-link collapsed'>
                                    Close Postition
                                    </NavLink>
                                </li> */}
                                    <li className="sidebar-item outcome">
                                        <NavLink to='/Positionhistory' className=' navlink sidebar-link collapsed'>
                                            Order
                                        </NavLink>
                                    </li>
                                </ul>
                            </li>
                            <li className="sidebar-item">
                                <a href='#' className="sidebar-link collapsed has-dropdown" onClick={() => handleSectionToggle('multi')}>
                                    <i className="bi bi-person-circle   " />
                                    <span>Spot Trade</span>
                                </a>
                                <ul id="multi" className={`sidebar-dropdown list-unstyled ${openSection === 'multi' ? 'show' : 'collapse'}`}>
                                    <li className="sidebar-item outcome">
                                        <NavLink to='/spottrade' className=' navlink sidebar-link collapsed'>
                                            New Trade
                                        </NavLink>
                                    </li>
                                    <li className="sidebar-item outcome">
                                        <NavLink to='/order' className=' navlink sidebar-link collapsed'>
                                            Order
                                        </NavLink>
                                    </li>
                                    <li className="sidebar-item outcome">
                                        <NavLink to='/trader' className=' navlink sidebar-link collapsed'>
                                        trades
                                        </NavLink>
                                    </li>
                                </ul>
                            </li>


                            <li>
                                <a href="#" className="sidebar-link" onClick={() => setIsLogoutModalOpen(true)}>
                                    <i class="bi bi-box-arrow-left"></i>
                                    <span>Logout</span>
                                </a>
                            </li>
                        </ul>
                    </aside>
                </div>


                <LogoutModal
                    isOpen={isLogoutModalOpen}
                    onCancel={() => setIsLogoutModalOpen(false)}
                    onConfirm={() => {
                        setIsLogoutModalOpen(false);
                        logout();
                    }}
                />



            </div>


        </>
    );
};

export default Sidebar;
